<template>
  <locations-template>
    <div class="merchat-top-container" slot="content">
      <v-row class="merchat-top-container-title-label">
        <div class="text-title">All locations</div>
      </v-row>

      <v-row v-show="showSearchTabs">
        <v-tabs v-model="tab">
          <v-tab> {{ $t('info') }} </v-tab>
          <v-tab>{{ $t('payouts') }} </v-tab>
          <div class="d-flex justify-end grow button-panel">
            <period-picker
              v-if="isPayoutsTab"
              @periodUpdated="updatePayoutPeriodDates"
              :isDatePicker="false"
            />
          </div>
        </v-tabs>
      </v-row>

      <v-row
        v-if="showSearchTabs"
      >
        <v-tabs-items v-model="tab">
          <v-tab-item  class="tab-item with-horizontal-padding">
            <v-divider/>
            <tab-all-merchants-info />
          </v-tab-item>
          <v-tab-item class="tab-item">
            <v-divider style="margin-left: 56px; margin-right: 295px" />
            <payouts-table :payoutsList="payoutsData" />
          </v-tab-item>
        </v-tabs-items>
      </v-row>
      <v-row v-else class="with-horizontal-padding">
        <div class="sales-block pt-7">
          <div class="tab-row-title">Sales</div>
          <div class="tab-row d-flex align-end mb-9">
            <v-divider class="mr-7" />
            <period-picker @periodUpdated="updatePeriodDates" />
          </div>
          <licence-plate-search-table
            :parkingsList="searchedByLicencePlateList"
            @loadMore="loadMoreResults"
          />
        </div>
      </v-row>
    </div>
  </locations-template>
</template>

<script>
import LocationsTemplate from '@/templates/Locations'
import TabAllMerchantsInfo from '../components/Merchant/Tab/Merchant/TabAllMerchantsInfo.vue'
import LicencePlateSearchTable from '@/components/Locations/AllLocations/LicencePlateSearchTable.vue'
import PayoutsTable from '@/components/Locations/AllLocations/PayoutsTable.vue'
import PeriodPicker from '@/components/Common/PeriodPicker.vue'
import merchantHelpers from '@/helpers/merchant'
import * as config from '@/config/config'
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import eventBus from '@/helpers/eventBus'
import auth from '@/auth'

export default {
  name: 'MerchantLocationsView',
  components: {
    LocationsTemplate,
    TabAllMerchantsInfo,
    LicencePlateSearchTable,
    PeriodPicker,
    PayoutsTable
  },
  data() {
    return {
      isEditingUserInfo: false,
      isAllLocationsLoaded: false,
      isShowingDeleteAccountPopup: false,
      isDeletingUser: false,
      activeForm: 'map',
      zones: [],
      selectedButton: 'map',
      tab: '',
      shouldZoomToCenter: null,
      moment: moment,
      startDate: null,
      endDate: null,
      payoutsData: null
    }
  },
  computed: {
    ...mapGetters([
      'showSearchTabs',
      'searchedByLicencePlateList',
      'searchByLicenceStartDate',
      'searchByLicenceEndDate',
      'allLocations',
      'filteredLocations'
    ]),
    isPayoutsTab() {
      return this.tab === 1
    }
  },
  methods: {
    ...mapActions(['setSearchByLicencePeriod']),
    changeForm(form) {
      this.activeForm = form
      this.selectedButton = form
    },
    async init() {
      if (auth.isParkmanAdmin()) {
        await this.$store.dispatch('setShowOverlay', true)
        const locations = await merchantHelpers.getNearByLocations(
        '60.165707737184,24.930013198585'
      )
      this.zones = locations
      await this.$store.dispatch('setSidebar', config.SIDEBAR.MERCHANT_ALL)
      await this.$store.dispatch('setMerchantLocations', locations) // this.$store.getters.allLocations
      this.shouldZoomToCenter = moment()
      await this.$store.dispatch('setSystemMessage', {
        message: this.$t('other_locations_will_be_displayed_soon'),
        type: 'success'
      })
      await merchantHelpers.getAllAccountLocations()
      this.isAllLocationsLoaded = true
      await this.$store.dispatch('setShowOverlay', false)
      } else {
        const merchants = await merchantHelpers.fetchAllMerchants()
        await this.$router.push({
            path: `/merchants/${merchants[0].id}`
        })
      }
    },
    updatePeriodDates({ startDate, endDate }) {
      if (
        this.searchByLicenceStartDate !== startDate ||
        this.searchByLicenceEndDate !== endDate
      ) {
        this.setSearchByLicencePeriod({ startDate, endDate })
        eventBus.$emit('reloadLicencePlates')
      }
    },
    async updatePayoutPeriodDates({ startDate, endDate }) {
      const startTimeStamp = moment.utc(startDate, 'DD.MM.YYYY').unix()
      const endTimeStamp = moment.utc(endDate, 'DD.MM.YYYY').endOf('day').unix()

      await this.$store.dispatch('setShowOverlay', true)
      this.payoutsData = await merchantHelpers.fetchAllCountryPayouts(startTimeStamp, endTimeStamp)
      await this.$store.dispatch('setShowOverlay', false)
    },
    loadMoreResults() {
      eventBus.$emit('loadLicencePlates')
    },
    showPayoutsTab() {
      this.tab = 1
    },
    async getReports() {
      await this.$store.dispatch('setShowOverlay', true)
      this.payoutsData = await merchantHelpers.fetchAllCountryPayouts(1724404029, 1727082429)
      await this.$store.dispatch('setShowOverlay', false)
    }
  },
  watch: {
    allLocations(newVal) {
      this.$store.dispatch('setMerchantLocations', newVal)
      this.zones = newVal
    },
    filteredLocations(newVal) {
      this.zones = newVal.length ? newVal : this.$store.getters.allLocations
    },
    searchByLicenceStartDate(date) {
      this.startDate = date
    },
    searchByLicenceEndDate(date) {
      this.endDate = date
    },
    async tab(value) {
      if (value === 0 && this.$route.query.tab) {
        this.init()
        this.$router.replace({ query: null })
      } else if (value === 1) {
        if (this.isAllLocationsLoaded === false) {
          this.init()
        }
      }
    }
  },
  async mounted() {
    if (this.$route.query.tab) {
      this.showPayoutsTab()
    } else {
      await this.init()
    }
    eventBus.$on('openPayouts', this.showPayoutsTab)
  },
  beforeDestroy() {
    eventBus.$off('openPayouts')
  }
}
</script>

<style lang="scss" scoped>
@import '~@/style/common';
@import '~@/style/variables';

.all-merchants-content {
  padding-top: $page-vertical-padding;
  text-align: start;
}
.custom-row {
  margin: 0px !important;
}
.tab-row {
  margin-top: -30px;
  &-title {
    color: $color-green;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0;
    min-width: 0;
    padding: 0;
    margin-right: 20px;
    text-transform: capitalize;
  }
}

.sales-block {
  width: 100%;
}

.button-panel {
  padding-right: 58px;
}

.payouts-tab-margins {
  margin-left: 44px;
  margin-right: 295px;
}
</style>

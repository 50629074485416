<template>
  <div class="mt-8 with-horizontal-padding">
    <v-data-table
      class="data__table"
      ref="table"
      :headers="headers"
      :items="mergedData"
      fixed-header
      hide-default-footer
      disable-pagination
      :mobile-breakpoint="mobileBreakpoint"
      :item-class="getRowClass"
      >
        <template v-slot:item.merchant_name="{ item }">
            <span v-if="item.isTotal" class="country-font">{{ item.merchant_name }}</span>
            <span v-else class="text-font">{{ item.merchant_name }}</span>
        </template>
        <template v-slot:item.total_merchant_payout_with_vat="{ item }">
          <span class="text-font">{{ roundToTwoSigns(item.total_merchant_payout_with_vat) }} {{ item.currency_symbol }}</span>
            
        </template>
        <template v-slot:item.total_merchant_payout_without_vat="{ item }">
          <span class="text-font">{{ roundToTwoSigns(item.total_merchant_payout_without_vat) }} {{ item.currency_symbol }}</span>
        </template>
    </v-data-table>
  </div>
</template>


<script>
import auth from '@/auth'
import moment from 'moment'

export default {
  name: 'PayoutsTable',
  props: {
    payoutsList: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      auth: auth,
      moment: moment,
      headers: [
        { text: this.$t('merchant'), value: 'merchant_name', sortable: false },
        { 
          text: this.$t('total_payout'), 
          value: 'total_merchant_payout_with_vat',
          sortable: false },
        {
          text: this.$t('total_payout_excl_vat'),
          value: 'total_merchant_payout_without_vat', 
          sortable: false
        }
      ],
      mobileBreakpoint: 767 // screen width in pixel to switch to mobile view
    }
  },
  computed: {
    isMobile() {
      return window.innerWidth <= this.mobileBreakpoint
    },
    mergedData() {
      if (this.payoutsList === null) {
        return []
      }
      const mergedList = []

      // Loop over each country in the payoutsList
      Object.keys(this.payoutsList).forEach(countryCode => {
        const countryData = this.payoutsList[countryCode]
        
        // Create a total payout "merchant" entry for the country
        const totalPayoutRow = {
          merchant_name: countryData.merchant_list[0].country_name,
          total_merchant_payout_with_vat: countryData.total_payout_with_vat,
          total_merchant_payout_without_vat: countryData.total_payout_without_vat,
          currency_symbol: countryData.currency_symbol,
          isTotal: true // Flag to identify this row as the total row
        }

        // Append the total payout row followed by the merchant list of that country
        mergedList.push(totalPayoutRow, ...countryData.merchant_list)
      })

      return mergedList
    }
  },
  methods: {
    loadMoreLicencePlateSearch() {
      this.$emit('loadMore')
    },
    roundToTwoSigns(num) {
      return num ? parseFloat(num).toFixed(2) : '0.00'
    },
    getRowClass(item) {
      if (item.isTotal) {
        return 'medium-row'
      }
      return 'small-row'
    }
  }
}
</script>

<style lang="scss" scoped>

@import '@/style/common';

.country-font {
  overflow: hidden;
  color: var(--web-text-main, #333);
  text-overflow: ellipsis;
  /* Desktop/Body text/16 - Admin, BD, CRM/bold */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 22.4px */
}

.text-font {
  overflow: hidden;
  color: var(--web-text-main, #333);
  text-overflow: ellipsis;
  white-space: nowrap;

  /* Desktop/table font/idle */
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 15.6px */
}
</style>

<template>
  <div class="mt-8">
    <div class="text-view">
      <v-row>
        <span class="form-dialog__title">Select a merchant account </span>
            </v-row>
      <v-row>
            <span class="text-message">This page is for ParkMan team to get a summary of all payouts we make to our merchants. In order to make changes to a particular merchant, please select a merchant account from the left menu.</span>
      </v-row>

      <zones-map
          ref="zonesMap"
          :map-created="onMapCreated"
          map-type-id="roadmap"
          :options="mapOptions"
        >
        </zones-map>
    </div>
  </div>
</template>

<script>
import { gmapApi } from 'vue2-google-maps'
import { MAP_SETTINGS } from '@/helpers/map'
import ZonesMap from '@/components/Common/ZonesMap.vue'

export default {
  components: {
    ZonesMap,
  },
  name: 'TabAllMerchantsInfo',
  data() {
    return {
      mapOptions: {
        styles: MAP_SETTINGS.styles,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        tilt: 0,
        fullscreenControlOptions: { position: 9 } // google.maps.ControlPosition.RIGHT_BOTTOM is just 9
      }
    }
  },
  computed: {
    google: gmapApi
  },
  methods: {
    onMapCreated() {}
  }
}
</script>

<style lang="scss" scoped>
.text-view {
      display: flex;
      width: 443px;
      padding: 0px 0px 0px 6px;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 6px;
}

.text-message {
      color: var(--web-text-main, #333);
      /* Desktop/Body text/14 px/regular */
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 19.6px */
      opacity: 0.8;
}
</style>